<template>
    <div class="container-box">
        <el-tabs v-model="Award_publicity_module" type="border-card" class="demo-tabs">
            <el-tab-pane label="教师赛" name="1">
                <el-table v-loading="loading" border :scrollbar-always-on="true" :data="data"
                           style="width: 100%;border-radius: 0.05rem;">
                           <el-table-column type="index" sortable label="序号" width="60" />
                           <el-table-column prop="school" sortable label="学校" width="120" />
                           <el-table-column prop="teacher" sortable label="教师">
                                <template #default="scope">
                                    <span>{{ scope.row.players[0].name }}</span>
                                </template>
                           </el-table-column>
                           <el-table-column prop="prize" sortable label="奖项" />
                       </el-table>
                       <!-- <div class="pagination-box">
                        <el-pagination @size-change="handleInnovateSizeChange" @current-change="handleInnovateCurrentChange"
                            v-model:current-page="this.innovatePage" v-model:page-size="this.innovatePageSize"
                            layout="total, prev, pager, next" :total="this.innovateTotal">
                        </el-pagination>
                    </div> -->
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { userInfo } from "api/apis.js";
import { ElMessage } from 'element-plus'
export default {
    name:'TeachPrizeAnnouncemen',
    data(){
        return {
            Award_publicity_module:'1',
            loading:false,
            data:[],
            innovateTotal: 0,//总数居
            innovatePage: 1,// 当前多少页
            innovatePageSize: 10,//当前显示多少条数,
        }
    },
    created(){
        this.getTeacherList();
    },
    methods:{
        getTeacherList(){
            let uid = this.$store.state.User.user_id
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            dataobj.append("type", '国赛');
            dataobj.append("track", '教师');
            userInfo.winnerList(dataobj).then(res=> {
                this.data = JSON.parse(res.data)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.container-box {
   width: 95%;
   margin: auto;
   // :deep(.el-tabs__content){
   //     height: 6.8rem;
   //     overflow-y: scroll;
   // }
}
</style>